@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Parisienne&family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Murecho&family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Do+Hyeon&family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

body {
  color: #e6e6e6;
}

.navigation .MuiListItemIcon-root {
  min-width: 40px !important;
}

.navigation .MuiListItemText-root {
  margin-right: 10px;
}

.navigation .MuiTypography-root {
  font-size: 14px !important;
}

.navigation .MuiListItem-root {
  max-height: 66px;
}

.navigation .MuiSvgIcon-root {
  transition: transform 0.32s ease-in-out !important;
}

.navigation .rotate {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  transition: transform 0.32s ease-in-out !important;
}

.dark .MuiDrawer-paper::-webkit-scrollbar {
  width: 6px;
  background-color: #222222;
}

.dark .MuiDrawer-paper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #222222;
  outline: 3px solid #222222;
}

.light .MuiDrawer-paper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #c6c6c6;
}

.light .MuiDrawer-paper::-webkit-scrollbar {
  width: 6px;
  background-color: #dedede;
}

.light .MuiDrawer-paper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #dedede;
  outline: 3px solid #dedede;
}

.dark .MuiDrawer-paper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #1c1c1c;
}

@media screen and (max-width: 600px) {
  .navigation .MuiListItemIcon-root {
    margin-left: -6px;
  }

  .css-4oxozc-MuiButtonBase-root-MuiIconButton-root.drawer-icon {
    margin-left: -13px;
  }
}

fieldset {
  border: none !important;
  outline: none !important;
}

.css-1xszriq-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  margin-top: -10px;
  margin-left: 6px;
}

.css-25310e-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  margin-top: -10px;
  margin-left: 6px;
}
